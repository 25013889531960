<template>
  <div>
    <!-- 封面图 -->
    <div class="banner" :style="cover">
      <div class="article-info-container">
        <!-- 文章标题 -->
        <div class="article-title">{{ title }}</div>
        <div class="article-info">
          <div class="first-line">
            <!-- 发表时间 -->
            <span>
              <i class="iconfont iconrili" />
              发表于 {{ createdAt }}
            </span>
            <div  v-if="hacker.length>0"><span class="iconfont icon-bug" style="color: red; font-size: 1.5rem"></span>发现疑似外挂</div>
          </div>
          <div class="second-line">
            <!-- 字数统计 -->
            <span>
              <i class="iconfont icon-caidao" />
              宰了: {{ killCount }}
            </span><span class="separator">|</span>
            <span>击败参战人数:{{ accessCount }} </span>
          </div>
          <br>
          <div class="third-line">
            <!-- 阅读量 -->
            <span>
              <i class="iconfont icon-jian" /> 总输出: {{ totalHurt }}
            </span><span class="separator">|</span>
            <span>
              <i class="iconfont" /> 敌军参战人数: {{ Math.max(totalHurtPerson, accessCount) }}
            </span>
          </div>
          <br>
          <div class="third-line" v-if="enemyTotalHurt > 0">
            <span>
              <i class="iconfont icon-anquandunpai" /> 总承受伤害: {{ enemyTotalHurt }}
            </span>
            <span v-if="showSelfHurt">
              <span class="separator">|</span>
              <i class="iconfont" /> 死亡次数: {{ deathCount }}
            </span>
            <span v-if="showSelfHurt">
              <span class="separator">|</span>
              <i class="iconfont" /> buff增益(队友...): {{ friends.length }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <v-row class="article-container">
      <v-col md="9" cols="12">
        <v-card v-if="killerLine.xAxis.data.length > 0">
          <div style="height:450px">
            <v-chart :option="killerLine" />
          </div>
          <hr>
        </v-card>

        <v-card>
          <div style="height:450px">
            <v-chart :option="radarOption" />
          </div>
          <hr>
        </v-card>
        <v-card v-if="killedpine.series[0].data.length > 0">
          <div style="height:450px">
            <v-chart :option="killedpine" />
          </div>
          <hr>
        </v-card>

        <v-card v-if="timeHurt.xAxis.data.length > 0">
          <div style="height:450px">
            <v-chart :option="timeHurt" />
          </div>
          <hr>
        </v-card>
        
        <v-card v-if="hurtPine.series[0].data.length > 0">
          <div style="height:450px">
            <v-chart :option="hurtPine" />
          </div>
          <hr>
        </v-card>

        <v-card v-if="personHurt.yAxis.data.length > 0">
          <div class="e-title">奔跑的五花肉</div>
          <div style="height:666px">
            <v-chart :option="personHurt" />
          </div>
          <hr>
        </v-card>

        <v-card v-if="killedRank2.yAxis.data.length > 0">
          <div class="e-title">敌将首级</div>
          <div style="height: 555px;">
            <v-chart :option="killedRank2" />
          </div>
          <hr>
        </v-card>

        <v-card v-if="killed.length > 0">
          <div class="e-title">耻辱墙</div>
            <div style="height:350px;">
              <wordcloud :data="killed"
                ref="word"
                nameKey="name"
                valueKey="value"
                :fontSize='[10, 60]'>
              </wordcloud>
          </div>
          <hr>
        </v-card>
        <v-card v-if="killed.length > 0">
          <div class="e-title">耻辱墙</div>
            <div style="height:350px;">
              <tag-cloud style="margin-top:1.5rem" :data="killed"/>
              <!-- <wordcloud :data="killed"
                ref="word"
                nameKey="name"
                valueKey="value"
                :fontSize='[10, 60]'>
              </wordcloud> -->
          </div>
        </v-card>
        <hr>
        <hr>
        <div>
          <v-card v-if="enemyTimeKiller.xAxis.data.length > 0">
            <div class="e-title" style="color: red;">被害榜</div>
            <div style="height:350px">
              <v-chart :option="enemyTimeKiller" />
            </div>
            <hr>
          </v-card>
          <v-card v-if="enemyTimeHurt.xAxis.data.length > 0">
            <div class="e-title" style="color: red;">承受伤害</div>
            <div style="height:350px">
              <v-chart :option="enemyTimeHurt" />
            </div>
            <hr>
          </v-card>
          <v-card v-if="enemyPersonHurtPine.series[0].data.length > 0">
            <div class="e-title" style="color: red;">承受伤害占比</div>
            <div style="height:444px">
              <v-chart :option="enemyPersonHurtPine" />
            </div>
            <hr>
          </v-card>
          <v-card v-if="enemyPersonKiller.yAxis.data.length > 0">
            <div class="e-title" style="color: red;">敌军猛将(人头)</div>
            <div style="height:444px">
              <v-chart :option="enemyPersonKiller" />
            </div>
            <hr>
          </v-card>
          <v-card v-if="enemyPersonHurt.yAxis.data.length > 0">
            <div class="e-title" style="color: red;">敌军猛将(伤害)</div>
            <div style="height:666px">
              <v-chart :option="enemyPersonHurt" />
            </div>
            <hr>
          </v-card>
          <v-card v-if="hacker.length > 0">
            <div class="e-title" style="color: red;">可疑数据</div>
            <table border="0" style="width: 80%; margin: 0 auto;">
              <tr v-for="(item, index) of hacker" :key="index" :class="index % 2 == 0 ? 'row-gray' : 'row-gray2'">
                <td>{{ item.line }}</td>
              </tr>
            </table>
            <hr>
          </v-card>
          <v-card v-if="enemyHurts.length > 0">
            <div class="e-title" style="color: red;">承受最大伤害top20</div>
            <table border="0" style="width: 80%; margin: 0 auto;">
              <tr>
                <td>时间</td>
                <td>敌军</td>
                <td>技能</td>
                <td>伤害</td>
              </tr>
              <tr v-for="(item, index) of enemyHurts" :key="index" :class="index % 2 == 0 ? 'row-gray' : 'row-gray2'">
                <td>{{ item.time }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.skill }}</td>
                <td>{{ item.count }}</td>
              </tr>
            </table>
          </v-card>
        </div>


        <!-- 版权声明 -->
        <div class="aritcle-copyright">
          <div>
            <span>文章作者：</span>
            <router-link to="/">
              11
            </router-link>
          </div>
          <div>
            <span>文章链接：</span>
            <a :href="articleHref" target="_blank">{{ articleHref }} </a>
          </div>
        </div>
        <!-- 点赞打赏等 -->
        <div class="article-reward">
          <!-- 点赞按钮 -->
          <a :class="isLike" @click="like">
            <v-icon size="14" color="#fff">mdi-thumb-up</v-icon> 点赞
            <span v-show="article.likeCount > 0">{{
              article.likeCount
            }}</span>
          </a>
        </div>
        <!-- 推荐文章 -->

        <!-- 分割线 -->
        <hr />
        <!-- 评论 -->
        <comment :type="commentType" @getCommentCount="getCommentCount" />
      </v-col>
      <!-- 侧边功能 -->
      <v-col md="3" cols="12" class="d-md-block d-none">
        <div style="position: sticky;top: 20px;">
          <!-- 文章目录 -->
          <!-- 最新文章 -->
          <v-card class="right-container" style="margin-top:20px">
            <div class="right-title">
              <i class="iconfont icongengxinshijian" style="font-size:8.8px" />
              <span style="margin-left:10px">最大伤害排行榜</span>
            </div>
            <div class="article-list">

              <div border="0" style="width: 100%; margin: 0 auto;">
                <div v-for="(item, index) of topHurt" :key="index" :class="index % 2 == 0 ? 'row-gray2' : ''">
                  <span>{{ item.time }} </span>
                  <span class="mr-3 iconfont icon-caidao"></span>
                  <span> {{ item.name }}</span><br>
                  <span>{{ item.skill }}</span>
                  <span style="color: red;"> {{ item.count }}</span>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import Comment from "../../components/Comment";
import "../../assets/js/china";
import tocbot from "tocbot";
import tagCloud  from "../../assets/js/tag-cloud.js";
import VueWordCloud from 'vue-wordcloud'
import Vue from "vue";
import ECharts from "vue-echarts";
import "vue-image-swipe/dist/vue-image-swipe.css";

Vue.component("v-chart", ECharts);


Vue.use(tagCloud);
Vue.component("wordcloud", VueWordCloud);

export default {
  components: {
    Comment
  },
  created() {
    this.getData();
  },
  destroyed() {
    tocbot.destroy();
  },
  data: function () {
    return {
      title: "",
      createdAt: new Date(),
      loading: true,
      imgList: [],
      accessCount: 0,
      enemyHurts: [],
      article: {
        nextArticle: {
          id: 0,
          articleCover: ""
        },
        lastArticle: {
          id: 0,
          articleCover: ""
        },
        recommendArticleList: [],
        newestArticleList: []
      },
      hacker:[],
      killCount: 0,
      totalHurt: 0,
      totalHurtPerson: 0,
      deathCount: 0,
      showSelfHurt: false,
      enemyTotalHurt: 0,
      readTime: "",
      commentType: 1,
      articleHref: window.location.href,
      type: 1,
      viewsCount: 0,
      messageCount: 0,
      userCount: 0,
      articleCount: 0,
      articleStatisticsList: [],
      killed: [],
      color: ['#1f77b4', '#629fc9', '#94bedb', '#c9e0ef'],
      topHurt: [],
      commentCount: 0,
      killerLine: {
        title: {
          text: '人头',
          left: 'left'
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          }
        },
        color: ["#3888fa"],
        legend: {
          data: ["击杀量"]
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "10%",
          containLabel: true
        },
        xAxis: {
          data: [],
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "#666"
            }
          }
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              // 设置y轴颜色
              color: "#048CCE"
            }
          }
        },
        series: [
          {
            name: "击杀",
            type: "line",
            data: [],
            smooth: true
          }
        ]
      },
      timeHurt: {
        title: {
          text: '输出',
          left: 'left'
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          }
        },
        color: ["#3888fa"],
        legend: {
          data: ["伤害输出"]
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "10%",
          containLabel: true
        },
        xAxis: {
          data: [],
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "#666"
            }
          }
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              // 设置y轴颜色
              color: "#048CCE"
            }
          }
        },
        series: [
          {
            name: "伤害",
            type: "line",
            data: [],
            smooth: true
          }
        ]
      },
      killedRank: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          }
        },
        color: ["#58AFFF"],
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "10%",
          containLabel: true
        },
        xAxis: {
          data: []
        },
        yAxis: {},
        series: [
          {
            name: ["辣手摧花"],
            type: "bar",
            data: []
          }
        ]
      },
      killedRank2: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          }
        },
        color: ["#58AFFF"],
        grid: {
          left: "10%",
          right: "10%",
          bottom: "0%",
          top: "0%",
          containLabel: false
        },
        xAxis: {},
        yAxis: {
          data: []
        },
        series: [
          {
            name: ["辣手摧花"],
            type: "bar",
            data: []
          }
        ]
      },
      personHurt: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          }
        },
        color: ["#58AFFF"],
        grid: {
          left: "10%",
          right: "10%",
          bottom: "0%",
          top: "0%",
          containLabel: false
        },
        xAxis: {},
        yAxis: {
          data: []
        },
        series: [
          {
            name: ["伤害榜"],
            type: "bar",
            data: []
          }
        ]
      },
      killedpine: {
        title: {
          text: '击杀榜',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '击杀次数',
            type: 'pie',
            radius: '80%',
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      hurtPine: {
        title: {
          text: '伤害榜',
          left: 'center'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        tooltip: {
          trigger: "item"
        },
        series: [
          {
            name: "总承受伤害",
            type: "pie",
            radius: '80%',
            data: []
          }
        ]
      },
      enemyTimeKiller: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          }
        },
        color: ["#998F66"],
        legend: {
          data: ["击杀量"]
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "10%",
          containLabel: true
        },
        xAxis: {
          data: [],
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "#998F66"
            }
          }
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              // 设置y轴颜色
              color: "#A79E7A"
            }
          }
        },
        series: [
          {
            name: "击杀",
            type: "line",
            data: [],
            smooth: true
          }
        ]
      },
      enemyTimeHurt: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          }
        },
        color: ["#998F66"],
        legend: {
          data: ["伤害输出"]
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "0%",
          top: "10%",
          containLabel: true
        },
        xAxis: {
          data: [],
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "#666"
            }
          }
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              // 设置y轴颜色
              color: "#A79E7A"
            }
          }
        },
        series: [
          {
            name: "伤害",
            type: "line",
            data: [],
            smooth: true
          }
        ]
      },
      enemyPersonHurtPine: {
        color: [
          "#555555",
          "#665F44",
          "#A79E7A",
          "#CFCBB8",
          "#D6D2C2",
          "#B4AD8F"
        ],
        legend: {
          data: [],
          bottom: "bottom"
        },
        tooltip: {
          trigger: "item"
        },
        series: [
          {
            name: "总承受伤害",
            type: "pie",
            roseType: "radius",
            data: []
          }
        ]
      },
      enemyPersonKiller: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          }
        },
        color: ["#A79E7A"],
        grid: {
          left: "10%",
          right: "10%",
          bottom: "0%",
          top: "0%",
          containLabel: false
        },
        xAxis: {},
        yAxis: {
          data: []
        },
        series: [
          {
            name: ["伤害榜"],
            type: "bar",
            data: []
          }
        ]
      },
      enemyPersonHurt: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          }
        },
        color: ["#A79E7A"],
        grid: {
          left: "10%",
          right: "10%",
          bottom: "0%",
          top: "0%",
          containLabel: false
        },
        xAxis: {},
        yAxis: {
          data: []
        },
        series: [
          {
            name: ["伤害榜"],
            type: "bar",
            data: []
          }
        ]
      },
      allBuffedPerson: [],
      friends: [],
      radarOption: {
        title: {
    text: '雷达分析'
  },
        backgroundColor: '#000',
        radar: {
          // shape: 'circle',
          center: ["50%", "50%"],
          // splitNumber: 3, // 雷达图圈数设置
          //图形的半径
          radius: '85%',
          name: {
            // 字体颜色
            textStyle: {
              color: "#fff",
              fontSize: 12,
              fontFamily: 'PingFangSC-Regular, PingFang SC;',
              // fontWeight: "500",
            },
          },
          // 设置雷达图中间射线的颜色
          axisLine: {
            lineStyle: {
              color: "#013A3F",
            },
          },
          indicator: [],
          //雷达图背景的颜色，在这儿随便设置了一个颜色，完全不透明度为0，就实现了透明背景
          splitArea: {
            show: true,
            areaStyle: {
              color: "rgba(255,0,0,0)", // 图表背景的颜色
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "#01424C", // 设置网格的颜色
            },
          },
        },
        series: [
          {
            name: "成绩单",
            type: "radar",
            data: [
              {
                value: [],
                // 标记的图形。
                // symbol: "rect",
                // 标记图形的大小
                // symbolSize: 0,
                symbol: "none", //去掉折线上的小圆点
                areaStyle: { color: "rgba(71,237,252,.3)" }, //阴影背景颜色
                lineStyle: {
                  type: 'line'
                },
              },
            ],
          },
        ],
      }
    };
  },
  methods: {
    like() {
      // 判断登录
      if (!this.$store.state.userId) {
        this.$store.state.loginFlag = true;
        return false;
      }
      //发送请求
      this.axios
        .post("/api/articles/" + this.article.id + "/like")
        .then(({ data }) => {
          if (data.flag) {
            //判断是否点赞
            if (
              this.$store.state.articleLikeSet.indexOf(this.article.id) != -1
            ) {
              this.$set(this.article, "likeCount", this.article.likeCount - 1);
            } else {
              this.$set(this.article, "likeCount", this.article.likeCount + 1);
            }
            this.$store.commit("articleLike", this.article.id);
          }
        });
    },
    previewImg(img) {
      this.$imagePreview({
        images: this.imgList,
        index: this.imgList.indexOf(img)
      });
    },
    deleteHTMLTag(content) {
      return content
        .replace(/<\/?[^>]*>/g, "")
        .replace(/[|]*\n/, "")
        .replace(/&npsp;/gi, "");
    },
    getCommentCount(count) {
      this.commentCount = count;
    },
    getData() {
      this.axios.get("/api" + this.$route.path).then(({ data }) => {
        document.title = data.data.title;
        this.title = data.data.title;
        this.createdAt = data.data.createdAt.substring(0, 19);
        this.createdAt = this.createdAt.substring(0, 10) + " " + this.createdAt.substring(11);
        // this.viewsCount = data.data.viewsCount;
        // this.messageCount = data.data.messageCount;
        // this.userCount = data.data.userCount;
        // this.articleCount = data.data.articleCount;
        // this.articleStatisticsList = data.data.articleStatisticsList;
        this.totalHurtPerson = data.data.enemyCount;
        this.deathCount = data.data.deathCount;

        if (data.data.killer != null) {
          data.data.killer.forEach(item => {
            this.killCount += item.count;
            this.killerLine.xAxis.data.push(item.name);
            this.killerLine.series[0].data.push(item.count);
          });
        }

        if (data.data.timeHurt != null) {
          data.data.timeHurt.forEach(item => {
            this.totalHurt += item.count;
            this.timeHurt.xAxis.data.push(item.name);
            this.timeHurt.series[0].data.push(item.count);
          });
        }

        if (data.data.killed != null) {
          data.data.killed.forEach(item => {
            this.accessCount += 1;
            this.killedRank.series[0].data.push(item.count);
            this.killedRank.xAxis.data.push(item.name);
          });
        }
        //柱状图
        if (data.data.killed != null) {
          data.data.killed.forEach(item => {
            this.killedRank2.series[0].data.unshift(item.count);
            this.killedRank2.yAxis.data.unshift(item.name);
          });

          //柱状图
          data.data.personHurt.forEach(item => {
            this.personHurt.series[0].data.unshift(item.count);
            this.personHurt.yAxis.data.unshift(item.name);
          });

          //词云
          data.data.personHurt.forEach(item => {
            this.killed.push({
              name: item.name,
              value: item.count
            });
          });

          //伤害饼图
          data.data.killed.forEach(item => {
            this.killedpine.series[0].data.push({
              value: item.count,
              name: item.name,
            });
          });

          //伤害饼图
          data.data.personHurt.forEach(item => {
            this.hurtPine.series[0].data.push({
              value: item.count,
              name: item.name,
            });
          });
        }

        if (data.data.topHurt != null) {
          data.data.topHurt.forEach(item => {
            // let content = item.name;
            // let cs = content.split('(')
            // let ctime = cs[0].substring(0, 10);
            // let hurt = cs[0].substring(16);
            // let userAndSkill = cs[1].split(')')[0].split(',')

            // content.slice(15,)
            this.topHurt.push({
              name: item.name,
              time: item.time,
              skill: item.skill,
              count: item.count,
            });
          });

          if (data.data.enemyTimeKiller != null) {
            data.data.enemyTimeKiller.forEach(item => {
              this.showSelfHurt = true;
              this.enemyTimeKiller.xAxis.data.push(item.name);
              this.enemyTimeKiller.series[0].data.push(item.count);
            });
          }


          if (data.data.enemyTimeHurt != null) {
            data.data.enemyTimeHurt.forEach(item => {
              this.enemyTotalHurt += item.count;
              this.enemyTimeHurt.xAxis.data.push(item.name);
              this.enemyTimeHurt.series[0].data.push(item.count);
            });
          }

          //伤害饼图
          data.data.enemyPersonHurt.forEach(item => {
            this.enemyPersonHurtPine.series[0].data.push({
              value: item.count,
              name: item.name,
            });
            this.enemyPersonHurtPine.legend.data.push(data.data.title);
          });
          //被杀次数。
          //伤害柱状图 enemyPersonKiller

          data.data.enemyPersonKiller.forEach(item => {
            this.enemyPersonKiller.series[0].data.unshift(item.count);
            this.enemyPersonKiller.yAxis.data.unshift(item.name);
          });
          data.data.enemyPersonHurt.forEach(item => {
            this.enemyPersonHurt.series[0].data.unshift(item.count);
            this.enemyPersonHurt.yAxis.data.unshift(item.name);
          });
          //enemyHurts
          data.data.enemyTopHurt.forEach(item => {
            this.enemyHurts.push({
              name: item.name,
              time: item.time,
              skill: item.skill,
              count: item.count,
            })
          });
        }
        if (data.data.allBuffedPerson != null) {
          //全部参战人员
          data.data.allBuffedPerson.forEach(item => {
            this.allBuffedPerson.push(item);
          })
        }
        if (data.data.friends != null) {
          //友军
          data.data.friends.forEach(item => {
            this.friends.push(item);
          })
        }
        if (data.data.radarOption != null) {
          //雷达
          data.data.radarOption.forEach(item => {
            this.radarOption.radar.indicator.push({
              name: item.name,
              max: 10000
            });
            this.radarOption.series[0].data[0].value.push(item.count)

          })
        }
        if (data.data.hacker != null) {
          //雷达
          this.hacker = data.data.hacker;
        }


        this.loading = false;
      });
    }
  },
  computed: {
    cover() {
      var cover = "";
      this.$store.state.blogInfo.pageList.forEach(item => {
        if (item.pageLabel == "sun") {
          cover = item.pageCover;
        }
      });
      return "background: url(" + cover + ") center center / cover no-repeat";
    },
    blogInfo() {
      return this.$store.state.blogInfo;
    },
    articleCover() {
      return (
        "background: url(" +
        this.article.articleCover +
        ") center center / cover no-repeat"
      );
    },
    isLike() {
      var articleLikeSet = this.$store.state.articleLikeSet;
      return articleLikeSet.indexOf(this.article.id) != -1
        ? "like-btn-active"
        : "like-btn";
    },
    isFull() {
      return function (id) {
        return id ? "post full" : "post";
      };
    }
  }
};
</script>

<style scoped>
table {
  border: 1;
}

.row-gray {
  background-color: #e7e7e7;
}

.row-gray2 {
  background-color: #dfdfdf;
}

.banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.article-info i {
  font-size: 14px;
}

.article-info {
  font-size: 14px;
  line-height: 1.9;
  display: inline-block;
}

@media (min-width: 760px) {
  .banner {
    color: #eee !important;
  }

  .article-info span {
    font-size: 95%;
  }

  .article-info-container {
    position: absolute;
    bottom: 6.25rem;
    padding: 0 0;
    width: 100%;
    text-align: center;
  }

  .second-line,
  .third-line {
    display: inline;
  }

  .article-title {
    font-size: 35px;
    margin: 8px 0 8px;
  }

  .pagination-post {
    display: flex;
  }

  .post {
    width: 50%;
  }

  .recommend-item {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 3px;
    width: calc(33.333% - 6px);
    height: 200px;
    background: #000;
    vertical-align: bottom;
  }
}

@media (max-width: 759px) {
  .banner {
    color: #eee !important;
    height: 360px;
  }

  .article-info span {
    font-size: 90%;
  }

  .separator:first-child {
    display: none;
  }

  .blog-container {
    margin: 322px 5px 0 5px;
  }

  .article-info-container {
    position: absolute;
    bottom: 1.3rem;
    padding: 0 5%;
    width: 100%;
    color: #eee;
    text-align: left;
  }

  .article-title {
    font-size: 1.5rem;
    margin-bottom: 0.4rem;
  }

  .post {
    width: 100%;
  }

  .pagination-post {
    display: block;
  }

  .recommend-item {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 3px;
    width: calc(100% - 4px);
    height: 150px;
    margin: 2px;
    background: #000;
    vertical-align: bottom;
  }
}

.article-operation {
  display: flex;
  align-items: center;
}

.article-category a {
  color: #fff !important;
}

.tag-container a {
  display: inline-block;
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 0 0.75rem;
  width: fit-content;
  border: 1px solid #49b1f5;
  border-radius: 1rem;
  color: #49b1f5 !important;
  font-size: 12px;
  line-height: 2;
}

.tag-container a:hover {
  color: #fff !important;
  background: #49b1f5;
  transition: all 0.5s;
}

.aritcle-copyright {
  position: relative;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  line-height: 2;
  padding: 0.625rem 1rem;
  border: 1px solid #eee;
}

.aritcle-copyright span {
  color: #49b1f5;
  font-weight: bold;
}

.aritcle-copyright a {
  text-decoration: underline !important;
  color: #99a9bf !important;
}

.aritcle-copyright:before {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  background: #49b1f5;
  content: "";
}

.aritcle-copyright:after {
  position: absolute;
  top: 0.95rem;
  right: 0.95rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5em;
  background: #fff;
  content: "";
}

.article-reward {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-btn {
  position: relative;
  display: inline-block;
  width: 100px;
  background: #49b1f5;
  margin: 0 1rem;
  color: #fff !important;
  text-align: center;
  line-height: 36px;
  font-size: 0.875rem;
}

.reward-btn:hover .reward-main {
  display: block;
}

.reward-main {
  display: none;
  position: absolute;
  bottom: 40px;
  left: 0;
  margin: 0;
  padding: 0 0 15px;
  width: 100%;
}

.reward-all {
  display: inline-block;
  margin: 0 0 0 -110px;
  padding: 20px 10px 8px !important;
  width: 320px;
  border-radius: 4px;
  background: #f5f5f5;
}

.reward-all:before {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 20px;
  content: "";
}

.reward-all:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 2px;
  left: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 13px solid #f5f5f5;
  border-right: 13px solid transparent;
  border-left: 13px solid transparent;
}

.reward-item {
  display: inline-block;
  padding: 0 8px;
  list-style-type: none;
}

.reward-img {
  width: 130px;
  height: 130px;
  display: block;
}

.reward-desc {
  margin: -5px 0;
  color: #858585;
  text-align: center;
}

.like-btn {
  display: inline-block;
  width: 100px;
  background: #969696;
  color: #fff !important;
  text-align: center;
  line-height: 36px;
  font-size: 0.875rem;
}

.like-btn-active {
  display: inline-block;
  width: 100px;
  background: #ec7259;
  color: #fff !important;
  text-align: center;
  line-height: 36px;
  font-size: 0.875rem;
}

.pagination-post {
  margin-top: 40px;
  overflow: hidden;
  width: 100%;
  background: #000;
}

.post {
  position: relative;
  height: 150px;
  overflow: hidden;
}

.post-info {
  position: absolute;
  top: 50%;
  padding: 20px 40px;
  width: 100%;
  transform: translate(0, -50%);
  line-height: 2;
  font-size: 14px;
}

.post-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  transition: all 0.6s;
  object-fit: cover;
}

.post a {
  position: relative;
  display: block;
  overflow: hidden;
  height: 150px;
}

.post:hover .post-cover {
  opacity: 0.8;
  transform: scale(1.1);
}

.label {
  font-size: 90%;
  color: #eee;
}

.post-title {
  font-weight: 500;
  color: #fff;
}

hr {
  position: relative;
  margin: 10px auto;
  border: 2px dashed #d2ebfd;
  width: calc(100% - 4px);
}

.full {
  width: 100% !important;
}

.right-container {
  padding: 20px 24px;
  font-size: 14px;
}

.right-title {
  display: flex;
  align-items: center;
  line-height: 2;
  font-size: 16.8px;
  margin-bottom: 6px;
}

.right-title i {
  font-weight: bold;
}

.recommend-container {
  margin-top: 40px;
}

.recommend-title {
  font-size: 20px;
  line-height: 2;
  font-weight: bold;
  margin-bottom: 5px;
}

.recommend-cover {
  width: 100%;
  height: 100%;
  opacity: 0.4;
  transition: all 0.6s;
  object-fit: cover;
}

.recommend-info {
  line-height: 2;
  color: #fff;
  position: absolute;
  top: 50%;
  padding: 0 20px;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
  font-size: 14px;
}

.recommend-date {
  font-size: 90%;
}

.recommend-item:hover .recommend-cover {
  opacity: 0.8;
  transform: scale(1.1);
}

.article-item {
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.article-item:first-child {
  padding-top: 0;
}

.article-item:last-child {
  padding-bottom: 0;
}

.article-item:not(:last-child) {
  border-bottom: 1px dashed #f5f5f5;
}

.article-item img {
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  object-fit: cover;
}

.article-item img:hover {
  transform: scale(1.1);
}

.content {
  flex: 1;
  padding-left: 10px;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.content-cover {
  width: 58.8px;
  height: 58.8px;
  overflow: hidden;
}

.content-title a {
  transition: all 0.2s;
  font-size: 95%;
}

.content-title a:hover {
  color: #2ba1d1;
}

.content-time {
  color: #858585;
  font-size: 85%;
  line-height: 2;
}

b.name {
  position: absolute;
  top: 7px;
  right: 45px;
  z-index: 1;
  color: #999;
  pointer-events: none;
}

.copy-btn {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 1;
  color: #ccc;
  background-color: #525252;
  border-radius: 6px;
  display: none;
  font-size: 14px;
  width: 32px;
  height: 24px;
  outline: none;

}


.blog-container {
  animation: main 1s;
  max-width: 970px;
  padding: 40px 40px;
  margin: 380px auto 40px auto;
}

.article-container {
  animation: main 1s;
  max-width: 1200px;
  margin: 372.5px auto 40px auto !important;
}

.article-wrapper {
  padding: 50px 40px;
}
</style>
